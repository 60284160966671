import axios from 'axios'
import { 
  API_BASE_URL
} from '../constants'

const login = async ( msisdn ) => {
  const {data: nonce} = await axios.get(`${API_BASE_URL}/wp-json/deals/v1/nonce`);

  return await axios.get(`${API_BASE_URL}/wp-json/deals/v1/auth/${msisdn}`, {
    headers: {
      'X-WP-Nonce': nonce
    }
  });
}


export default login;