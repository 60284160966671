import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import BG_IMG from '../../assets/images/BG.jpg'
import {ReactComponent as Logo} from '../../assets/svg/wobotom-logo.svg'
import Button from '../../components/Button'
import URLSearch from '../../utils/urlSearch'
import useStore from '../../store'
import toast from 'react-hot-toast'
import Toast from '../../components/Toast'
import { BottomSheet } from 'react-spring-bottom-sheet'
import Subscription from '../../components/Subscription'
import axios from 'axios'
import { API_BASE_URL } from '../../constants'
import { useQuery } from 'react-query'
import {ReactComponent as Telecel} from '../../assets/svg/telecel.svg'

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { addUser, addToken, token } = useStore();
  const isMounted = useRef(true);
  const { isLoading: loadingNonce, data: nonce } = useQuery(['nonce'], async () => {
    const resp = await axios.get(`${API_BASE_URL}/wp-json/deals/v1/nonce`);
    return resp.data;
  })

  const openBottomSheet = () => {
    setOpen(true);
    setLoading(true);
  }

  const onDismiss = () => {
    setOpen(false);
    setLoading(false);
  };

  const logUserIn = async ({
    msisdn
  }) => {
    setLoading(true);
    // const hash = btoa(`${process.env.REACT_APP_SALT}:${msisdn}:${subscriptionId}`)

    // const expiresAt = new Date().setMinutes(new Date().getMinutes() + 2);
    const resp = await axios.get(`${API_BASE_URL}/wp-json/deals/v1/auth/${msisdn}`, {
      headers: {
        'X-WP-Nonce': nonce
      }
    });
    // console.log(resp);
    if ( resp.status === 200 ) {
      addToken(resp.data.token);
      navigate("/deals");
    }
  }

  useEffect(() => {
    if(isMounted.current && ! loadingNonce) {
      const params = URLSearch();
      if(params) {
        console.table(params);
        const msisdn = params.msisdn.trim();
        console.log('After Telenity', msisdn);
        if(params.result === 'true' && msisdn !== '') {
          toast.custom(
            <Toast 
              variant="success" 
              title="Yay! you're subscribed"
            >
              Congrats. We are loading your content.
            </Toast>
          )

          addUser({
            msisdn
          })
          setLoading(false);
          logUserIn(params);
        } else {
          toast.custom(
            <Toast variant="error" title="Subscription failed">
              Sorry! There was a problem with your request.
            </Toast>
          )
          setLoading(false);
        }
  
        // window.location.replace(window.location.origin)
      }

      isMounted.current = false;
    }
  }, [loadingNonce]); // eslint-disable-line

  useEffect(() => {
    if( token!=='' ) {
      navigate('/deals');
    }
  }, []); // eslint-disable-line

  
  return (
    <div
     className="flex flex-col h-screen max-w-xl"
    >
      <div className="relative">
        <div className="absolute pt-10 pl-11 w-full flex justify-between">
          <Logo />

          <div className='pr-11'><Telecel /></div>
        </div>
        <img src={BG_IMG} alt="background" />
      </div>
      <div className="pl-11 pr-11 relative">
        <h1 className='text-3xl font-bold -mt-5 mb-4'>Be the first to get all the juicy deals and discounts.</h1>

        <p>Subscribe at 0.25p to enjoy the latest flash deals, promo codes, offers and vouchers on BigDeals. </p>
      </div>

      <div className="px-6 mt-8 flex flex-col  pb-8">
          <Button onClick={openBottomSheet} loading={loading}>Let's Go</Button>
          <Button transparent onClick={() => navigate("suggest-a-deal")}>SUGGEST A DEAL</Button>
      </div>

      <BottomSheet
        open={open}
        onDismiss={onDismiss}
        snapPoints={({ minHeight }) => minHeight}
      >
        <div className="max-w-sm mx-auto"><Subscription /></div>
      </BottomSheet>
    </div>
  )
}

export default Home