import { useState } from 'react';
import { animated, useTransition } from 'react-spring';
import Button from './Button';
import unsubscribe from '../services/unsubscribe.service';
import useStore, { logout } from '../store';
import nationalizeTel from '../utils/nationalizeTel';
import toast from 'react-hot-toast'
import Toast from './Toast'

const UnsubscribeButton = () => {
  const [prompt, set] = useState(false);
  const [loading, setLoading] = useState(false);
  const msisdn = useStore(s=>s.msisdn);
  const transition = useTransition(prompt, {
    from: {transform: 'translate3d(110%,0,0)', position: 'absolute'},
    enter: {transform: 'translate3d(0%,0,0)', position: 'relative'},
    leave: {transform: 'translate3d(-110%,0,0)', position: 'absolute'}
  })

  const handleUnsubscribing = () => {
    setLoading(true);
    unsubscribe(nationalizeTel(msisdn))
    .then(() => {
      logout();
      
      toast.custom(
        <Toast 
          variant="success" 
          title="Hmmm!"
        >
          You have unsubscribed.
        </Toast>
      )
    })
    .catch(error => {
      toast.custom(
        <Toast 
          variant="error" 
          title="Oh! erm..."
        >
          {error.message}
        </Toast>
      )
    })
  }

  return (
    <div className="py-3 pb-4">
      {transition((styles, showPrompt) => showPrompt ? 
      (
        <animated.div style={styles} className="flex items-center">
          <div className="flex-1 text-sm">Are you sure about unsubscribing?</div>
         
          <div className="flex items-center gap-4 pr-2">
            <Button 
              type="submit" 
              role="menuitem"
              tabIndex="-1"
              size="sm"
              wide={false}
              onClick={() => set(false)}
              disabled={loading}
            >
              No
            </Button>
            <Button primary transparent loading={loading} onClick={handleUnsubscribing}>Yes</Button>
          </div>
        </animated.div>
      ) : (
        <animated.div style={styles} className="flex pl-4">
          <Button 
            type="submit" 
            role="menuitem"
            tabIndex="-1"
            size="sm"
            wide={false}
            onClick={() => set(true)}
          >
            Unsubscribe
          </Button>
        </animated.div>
      ))}
    </div>
  )
}

export default UnsubscribeButton