import React from 'react'

function DealImage({
  data,
  title
}) {
  return data?.srcset ? (
    <img srcSet={data?.srcset} alt={title} className='w-full' />
  ): (
    <img src={data.fallback?.url} alt={title} className='w-full' />
  )
}

export default DealImage