import {ReactComponent as LeftArrow} from '../../assets/svg/left-arrow.svg'
import { useNavigate } from 'react-router-dom'
import SuggestADealForm from '../../components/SuggestADealForm'

const SuggestDeal = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="absolute w-full h-full overflow-hidden -z-10">
        <div className="absolute bg-primary bg-opacity-5 w-[75%] h-[65%] rounded-2xl -top-7"></div>
        <div className="absolute border-[150px] border-[#FFFBED] rounded-full w-[126%] pb-[50%] -bottom-1/4 -right-[63%]"></div>
      </div>

      <div className='pt-8 z-10'>
        <div className="px-6 mb-10">
          <div className="flex items-center gap-5 mb-4">
            <button onClick={() => navigate(-1)} className='outline-none'><LeftArrow /></button>
            <h1 className='uppercase text-primary font-bold'>
              Suggest a Deal
            </h1>
          </div>

          <p>Know a great deal we might be interested in? Let us know 😊.</p>
        </div>

        <div className="px-4 pb-8">
          <SuggestADealForm />
        </div>
      </div>
    </>
  )
}

export default SuggestDeal