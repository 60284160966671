import classNames from "classnames/bind"
import {ReactComponent as Close} from '../assets/svg/cross.svg'
import {ReactComponent as Tick} from '../assets/svg/tick.svg'
import {ReactComponent as Warning} from '../assets/svg/warning.svg'

const style = {
  wrapper: "bg-white p-2 w-full rounded-md max-w-md shadow-lg",
  icon: "rounded-full w-7 h-7 inline-flex justify-center items-center",
  bar: "w-1 rounded-lg h-full block absolute left-0 top-1/2 -translate-y-1/2",
  info: "bg-[#047bc4]",
  warning: "bg-[#f0a92c]",
  success: "bg-[#32a679]",
  error: "bg-[#ef3935]"
}

let cx = classNames.bind(style);

const Toast = ({
  children,
  title,
  variant,
  onDismiss
}) => {
  return (
    <div className={cx("wrapper", "shadow-stone-800/25")}>
      <div className={classNames(
        "flex w-full items-center relative",
        {"pl-4" : variant !== 'default'}
      )}>
        {variant !== 'default' && <>
        
        <span className={cx("bar", variant)}></span>

        <div className={cx("icon",variant)}>
          {
            {
              info: <Warning />,
              warning: <Warning />,
              success: <Tick className="stroke-white" />,
              error: <Close className="fill-white" />
            }[variant]
          }
        </div>
        </>}

        <div className="pl-3 flex-1">
          {title !== '' && <h1 className="text-[#272d36] text-base font-medium">{title}</h1>}
          <p className="text-[#94959c] font-normal text-sm">{children}</p>
        </div>

        {onDismiss && 
          <p className="p-2 cursor-pointer" onClick={onDismiss}>
            <Close className="fill-[#94959c] w-5" />
          </p>
        }
      </div>
    </div>
  )
}

export default Toast

Toast.defaultProps = {
  variant: 'default'
}