import create from 'zustand'
import { persist } from 'zustand/middleware'

const useStore = create(
  persist(
    (set) => ({
      addUser: (user) => {
        set({...user})
      },
      addToken: (token) => set(state => ({
        ...state,
        token
      })),
      logout: () => set({token: '', msisdn: ''})
    }),
    {
      name: 'deals-storage',
      getStorage: () => localStorage
    }
  )
)

export const logout = useStore.getState().logout;

export default useStore;