import { TELENITY_SUB_URL } from '../constants';

const client_id = process.env.REACT_APP_TELENITY_CLIENT_ID;

const unsubscribe = async (msisdn) => {
  const params = new URLSearchParams({
    activity: 'unsubscribe',
    client_id
  }).toString();

  const resource = `${TELENITY_SUB_URL}?${params}`;
  const response = await fetch(resource, {    
    method: 'POST',
    body: JSON.stringify({"channel":"web",
    "msisdn": msisdn})
  });

  if(!response.ok) {
    throw new Error('Failed to unsubscribe');
  }

  return await response.json();
};

export default unsubscribe;