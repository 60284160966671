import Classnames from 'classnames';

const Button = ({
  children,
  onClick,
  transparent,
  primary,
  wide,
  size,
  loading,
  disabled,
  ...rest
}) => {
  return (
    <button 
      onClick={onClick}
      disabled={disabled}
      className={Classnames(
        "h-16 rounded-[14px] text-primary uppercase inline-flex items-center justify-center",
        {
          "bg-primary !text-white": primary && !transparent,
          "!bg-gray-400": disabled,
          "w-full": wide,
          "text-lg font-medium": size === 'lg',
          "!h-[46px] !px-6 !text-sm": size === 'sm',
          "!text-primary": loading && transparent
        }
      )}
      {...rest}
    >
      {loading ? 
        <>
          <svg className={Classnames(
            "animate-spin -ml-1 mr-3 h-5 w-5 text-white",
            {
              "!text-primary": primary && transparent
            }
          )} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </>
      :
        children
      }
    </button>
  )
}

export default Button

Button.defaultProps = {
  primary: true,
  wide: true,
  size: 'lg'
}