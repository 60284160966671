import { useRef, useState, useEffect } from 'react'
import { 
  IconBrandWhatsapp, IconBrandFacebook, IconBrandTwitter,
  IconCopy, IconCheck
} from '@tabler/icons'
import {ReactComponent as ShareIcon} from '../assets/svg/share.svg'

const ShareButton = ({ post }) => {
  const [copied, setCopied] = useState(false);
  const buttonRef = useRef(null);
  const shareMessage = useRef(`Check out this offer I found: ${encodeURIComponent(window.location.href+'/'+post)}`);
// encodeURIComponent

  const closeSharing = () => {
    buttonRef.current.closest('.item-entry').classList.remove('z-50');
    document.querySelector('.overlay').classList.remove('show')
    buttonRef.current.parentNode.classList.remove('active');
  }

  const handleCopy = () => {
    const copyText = document.getElementById('copyMsg');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);

    setCopied(true);
    
    setTimeout(() => {
      setCopied(false);
      closeSharing();
    }, 750);
  }

  const handleShareButton = () => {
    buttonRef.current.parentNode.classList.toggle('active');
    buttonRef.current.closest('.item-entry').classList.toggle('z-50');
    document.querySelector('.overlay').classList.toggle('show')
    // if (navigator.share) {
    //   navigator.share({
    //     title: 'WebShare API Demo',
    //     url: 'https://codepen.io/ayoisaiah/pen/YbNazJ'
    //   }).then(() => {
    //     console.log('Thanks for sharing!');
    //   })
    //   .catch(console.error);
    // } else {
    //   // the fallback
    //   buttonRef.current.parentNode.classList.toggle('active');
    //   buttonRef.current.closest('.item-entry').classList.toggle('z-50');
    //   document.querySelector('.overlay').classList.toggle('show')
    // }
  };

  useEffect(() => {
    const overlay = document.querySelector('.overlay');
    overlay.addEventListener('click', closeSharing);

    return () => {
      if(overlay) {
        overlay.removeEventListener('click', closeSharing)
      }
    };
  },[]);

  return (
    <div className="sharerus">
      <button 
        className='btn' 
        onClick={handleShareButton}
        ref={buttonRef}
      >
        <ShareIcon />
      </button>

      <ul className='list'>
        <li className='item'>
          <span
            className='link text-black'
            onClick={handleCopy}
          >
            <span className="hidden">
              <input id="copyMsg" value={shareMessage.current} type="text" readOnly />
            </span>
            {copied ? (
              <IconCheck stroke={2} size={18} color="#00b341" />
            ) : (
              <IconCopy stroke={2} size={18} color="#657786" />
            )}
          </span>
        </li>

        <li className='item'>
          <a 
            href={`https://twitter.com/share?text=${shareMessage.current}`}
            target="_blank" rel="canonical noreferrer" 
            className='link text-black'
          >
            <IconBrandTwitter stroke={2} size={18} color="#1DA1F2" />
          </a>
        </li>

        <li className='item'>
          <a 
            href={`whatsapp://send?text=${shareMessage.current}`}
            action="share/whatsapp/share"
            target="_blank" rel="canonical noreferrer" 
            className='link text-black'
          >
            <IconBrandWhatsapp stroke={2} size={18} color="#25D366" />
          </a>
        </li>

        <li className='item'>
          <a 
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareMessage.current}`}
            rel="canonical" 
            className='link text-black'
          >
            <IconBrandFacebook stroke={2} size={18} color="#4267B2" />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default ShareButton