import {ReactComponent as Logo} from '../assets/svg/wobotom-logo.svg'
// import { ReactComponent as Vodafone } from '../assets/svg/vodafone.svg'
import {ReactComponent as Telecel} from '../assets/svg/telecel.svg'
import Hamburger from './Hamburger'

const Navbar = () => {
  return (
    <div className='relative flex items-center justify-center w-full px-4 pt-10'>
      <div className="absolute left-4 flex">
        <Hamburger />
      </div>
      
      <div className="flex space-x-2">
        <Logo />

        <span className='border-r-2 border-black'></span>

        <Telecel className="w-10 h-auto" />
      </div>
    </div>
  )
}

export default Navbar