import { useState, useEffect, useCallback } from 'react'
import Button from './Button'
import Input from './Input'
import Textarea from './Textarea'
import { ReactComponent as Paper } from '../assets/svg/paper.svg'
import axios from 'axios'
import { API_BASE_URL } from '../constants'
import Toast from './Toast'
import toast from 'react-hot-toast'

const SuggestADealForm = () => {
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [media, setMedia] = useState([]);
  const [mediaFileName, setMediaFileName] = useState("");
  const [mediaError, setMediaError] = useState();
  const [formData, setFormData] = useState({
    brand: {value: '', required: true},
    url: {value: '', required: true},
    number: {value: ''},
    description: {value: ''}
  });

  const validate = useCallback((fieldName, value) => {
    let is_valid = true;
    switch(fieldName) {
      case 'brand':
        is_valid = value !== "";
        break;

      case 'url':
        is_valid = value !== '';
        break;

        default:
          break;
    };

    return is_valid;
  }, []);

  const reset = () => {
    setMedia(null);
    setFormData(s => {
      let newData = s;
      Object.keys(s).map(k => newData[k].value = '')
      return newData;
    });
    setMediaFileName("");
  };

  const handleOnChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setFormData(prev => ({
      ...prev,
      [name]: {
        value: value,
        isValid: prev[name].required ? validate(name, value) : true
      }
    }));
  }

  const handleMediaSelect = (event) => {
    const file = event.target.files[0];

    if(file.size > 2097152) {
      setMediaError("File size cannot exceed more than 2MB");
    } else {
      setMediaError('');
      setMediaFileName(file.name);
      setMedia(file);
    }
  }

  const handleSubmit = () => {
    setLoading(true);
    const newFormData = new FormData();

    for(const i in formData) {
      let key = i;
      if(i === 'brand') {
        key = 'title'
      }
      newFormData.append(key, formData[i].value);
    }

    if(media.name !== undefined) {
      newFormData.append('media', media, mediaFileName);
    }
    
    axios
      .post(`${API_BASE_URL}/wp-json/deals/v1/suggest-a-deal`, newFormData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(response => {
        if ( response.status === 200 ) {
          setLoading(false);
          toast.custom(
            <Toast 
              variant="success" 
              title="Yay! suggestion sent"
            >
              Thank you. We've received your suggestion.
            </Toast>
          );

          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.custom(
          <Toast 
            variant="error" 
            title="Failed to submit"
          >
            {err}
          </Toast>
        )
      })

  }

  useEffect(() => {
    if( Object.keys(formData).filter( key => {
      const field = formData[key];
      return (field.required && field.isValid  !== true)
    } ).length >= 1 
    ) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [formData]);

  return (
    <>
      <div className="mb-[18px]">
        <Input 
          placeholder={<>Name of brand <span className="!text-red-500">*</span></>}
          inputProps={{
            onChange: handleOnChange,
            value: formData.brand.value,
            name: "brand"
          }}
        />
      </div>

      <div className="mb-[18px]">
        <Input 
          placeholder={<>Enter website/social media URL <span className="!text-red-500">*</span></>} 
          inputProps={{
            onChange: handleOnChange,
            name: "url",
            value: formData.url.value
          }}
        />
      </div>

      <div className="mb-[18px]">
        <Input 
          placeholder={<>Enter phone number</>} 
          inputProps={{
            name: "number",
            value: formData.number.value,
            onChange: handleOnChange
          }}
        />
      </div>

      <div className="mb-[18px]">
        <div className="relative shadow rounded-[4px] flex flex-col bg-white py-4">
          <label htmlFor="media" className="w-full focus:outline-none appearance-none cursor-pointer">
            <div className="flex px-8 gap-4">
              <div>
                <div className="bg-[#FBF3DB] p-1 rounded-md"><Paper className="stroke-primary" /></div>
              </div>
              <div className="flex-1">
                <div className="flex flex-col divide-y space-y-1 divide-gray-100 text-gray-300 text-xs">
                  <p className="">
                    {mediaFileName ? 
                      <span className="text-gray-900">{mediaFileName}</span> : 
                      <>Upload Image if available</>
                    }
                  </p>
                  <div className="flex justify-between pr-2 pt-1">
                    {mediaError ? 
                      <span className='text-red-500'>
                        {mediaError}
                      </span> 
                    : 
                      <>
                        <p>Accepted file type: jpg/png</p>
                        <span className="text-gray-500">Max: 2MB</span>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>

            <input 
              name="media" 
              id="media" 
              type="file" 
              className='hidden'
              onChange={handleMediaSelect}
            />
          </label>
        </div>
      </div>

      <div className="mb-[18px]">
        <Textarea 
          placeholder={<>Describe the offer <span>*</span></>} 
          textareaProps={{
            name: "description",
            value: formData.description.value,
            onChange: handleOnChange
          }}
        />
      </div>

      <div className="mt-14">
        <Button disabled={!formValid || loading} loading={loading} onClick={handleSubmit}>Submit</Button>
      </div></>
  )
}

export default SuggestADealForm