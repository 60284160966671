import { useEffect, useRef, useCallback, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import request from "../../utils/axios"
import classNames from 'classnames'
import Navbar from "../../components/Navbar"
import Finder from "../../components/Finder"
import ShareButton from '../../components/ShareButton'
import DealImage from '../../components/DealImage'
import {ReactComponent as CallIcon} from '../../assets/svg/call.svg'
import {ReactComponent as GlobeIcon} from '../../assets/svg/globe.svg'

const Deals = () => {
  const [filter, setFilter] = useState(0);
  const observerElem = useRef(null);
  const { 
    data,
    isSuccess, 
    hasNextPage, 
    fetchNextPage, 
    isFetchingNextPage
  } = useInfiniteQuery(['deals', filter], async ({pageParam = 1}) => {
    const query = {page: pageParam};
    if (filter) {
      query.filter = filter;
    }

    const queryStr = Object.keys(query).map(key => `${key}=${encodeURIComponent(query[key])}`).join('&');
    const url = '/wp-json/deals/v1/posts?' + queryStr;
    try {
      const { data } = await request({
        url
      });  
      return data;
    } catch (error) {
      // console.log('caught', error.message)
      return [];
    }
  }, {
    retry: false,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1;
      return lastPage.length !== 0 ? nextPage : undefined;
    }
  });

  const handleFilter = ( query ) => {
    if ( query ) {
      setFilter(query.value);
    } else {
      setFilter(0);
    }
  }

  const handleObserver = useCallback((entries) => {
    const [target] = entries
    if(target.isIntersecting && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    const element = observerElem.current
    const option = { threshold: 0 }
  
    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element)
    return () => observer.unobserve(element)
  }, [fetchNextPage, hasNextPage, handleObserver])

  return (
    <div>
      <Navbar />

      <div className="py-14 px-4">
        <Finder onFilter={handleFilter} />
      </div>

      <div className="px-4 space-y-8">
        {!isSuccess && <>Loading...</>}

        {isSuccess && data.pages.map(page => page && page.map(deal => {
          const {
            id,
            deal_image: imgObj,
            deal_website: website,
            deal_number: number,
            deal_expiry: expiry,
            title
          } = deal;

          return <div className='item-entry relative'
            key={id}
          >
            {Object.keys(imgObj).length > 0 ?
              <DealImage data={imgObj} title={title} />
            : null }
            {/* {Object.keys(imgObj).length > 0 
            && 
            <img srcSet={imgObj.srcset} src={imgObj.sizes['deal-featured-image']?.url} alt={title} className='w-full' />}

            {imgObj.srcset 
            ? (
              <img srcSet={imgObj.srcset} alt={title} className='w-full' />
            )
            : (
          <img srcSet={imgObj.srcset} src={imgObj.sizes['deal-featured-image']?.url} alt={title} className='w-full' />
            )} */}

            <div className="bg-primary text-white leading-4 px-4 py-3">
              <div className="flex">
                <div className="flex-1">
                  {title !== '' ? <><h1 className='font-bold mb-2'>{title}</h1></> : null}
                  {expiry && <small className="text-xs"><p className='mb-2 -mt-1'>Offer ends on {expiry}</p></small>}

                  <div className="flex space-x-2">
                    {website !=='' ? 
                      <a href={website} target="_blank" data-url={website} rel="noreferrer" className="bg-white rounded-2xl px-3 flex items-center justify-space text-black h-8">
                        <GlobeIcon /> <p className='ml-2'>visit</p>
                      </a>
                      : null
                    }
                    
                    {number ?
                      <a href={`tel:${number}`} className="bg-white rounded-2xl px-3 flex items-center justify-space text-black h-8"><CallIcon /> <p className='ml-2'>call</p></a>
                      : null
                    }
                  </div>
                </div>
                <div className="mt-auto">
                  <ShareButton post={id} />
                </div>
              </div>
            </div>
        </div>
        }))}
      </div>

      <div ref={observerElem} className={classNames()}>
        {isSuccess ?
          <div className="text-center py-2">
            {isFetchingNextPage && hasNextPage ? 'loading' : 'You\'ve reached the end of the list.'}
          </div>
          : null
        }
      </div>

      <div className="overlay"></div>
    </div>
  )
}

export default Deals
