import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import TelInput from './TelInput'
import { 
  TELENITY_URL, 
  VERIFY_SUB_URL,
  TELENITY_SUB_URL
} from '../constants'
import axios from 'axios'
import toast from 'react-hot-toast'
import Toast from './Toast'
import useStore from '../store'
import login from '../services/login'

const telNationalize = (number) => `233${number.substring(1)}`;

const Subscription = () => {
  const [loading, setLoading] = useState(false);
  const [disable, setDisabled] = useState(true);
  const [message, setMessage] = useState('');
  const [number, setNumber] = useState('');
  const navigate = useNavigate();
  const addUser = useStore(s=> s.addUser);

  const verifyNumber = async () => {
    setLoading(true);

    try {
      const {data} = await axios.get(`${VERIFY_SUB_URL}?num=${telNationalize(number)}&offer_id=379`);

      if(data.status) {
        toast.custom(
          <Toast 
            variant="success" 
            title="Yay! you're subscribed"
          >
            Congrats. We are loading your content.
          </Toast>
        )
        
        const {status, data: { token }} = await login(number);
        
        if(status === 200) {
          addUser( { msisdn: number, token } );
          navigate("/deals");
        }
      } else {
        setMessage("You are not subscribed. Please wait while we redirect you to subscription page.");

        setTimeout(() => {
          handleSubscription();
        }, 1800);
      }
    } catch (error) {
      
    }
  }

  const toggleButton = (isValid) => {
    if(isValid) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  const handleSubscription = async () => {
    const cb_url = `${window.location.origin}`;
    const client_id = process.env.REACT_APP_TELENITY_CLIENT_ID;
    setLoading(true);

    const {data: {access_token}} = await axios.get(`${TELENITY_SUB_URL}?client_id=${client_id}&activity=token`);

    const endpoint = encodeURIComponent(`token=${access_token}&callbackUrl=${cb_url}&action=signup&msisdn=${telNationalize(number)}`);
    window.location.assign(`${TELENITY_URL}?${endpoint}`);
  }

  return (
    <div className='p-4'>
      <p className='mb-6'>
        {
          message !== '' 
          ? 
            <span className='text-yellow-500'>{message}</span> 
          :
            <>Let's check if you are already subscribed and we can send you on your way.</>
        }
      </p>

      <div className="mb-[18px]">
        <TelInput 
          placeholder={<>Enter your number <span className="!text-red-500">*</span></>} 
          inputProps={{
            onPhoneNumberChange: (isValid, number, country) => {
              toggleButton(isValid);
              setNumber(number);
            },
            onPhoneNumberBlur: (isValid) => toggleButton(isValid)
          }}
        />
      </div>

      <Button loading={loading} disabled={disable} onClick={verifyNumber}>Continue</Button>
    </div>
  )
}

export default Subscription