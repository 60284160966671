import React from 'react'

const Input = ({
  placeholder,
  inputProps
}) => {
  return (
    <div className="relative shadow rounded-[4px] flex flex-col bg-white">
      <input type="text" id={inputProps.name} placeholder=" "
        className='z-20 block h-[66px] px-4 pl-8 w-full text-sm text-gray-900 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer'
        autoComplete='off'
        {...inputProps}
      />
      <label 
        htmlFor={inputProps.name}
        className='absolute text-sm text-gray-300 duration-300 transform -translate-y-4 scale-75 top-5 left-8 z-0 origin-[0] peer-focus:left-8 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4'
      >
        {placeholder}
      </label>
    </div>
  )
}

export default Input
