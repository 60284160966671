import axios from 'axios'
import { API_BASE_URL } from '../constants'
import useStore, { logout } from '../store'


const client = axios.create( {
  baseURL: API_BASE_URL
} );

const request = ({ ...options }) => {
  const {
    token: authToken,
    msisdn
  } = useStore.getState();
  client.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  client.defaults.headers.common['X-WP-MSISDN'] = '0'+msisdn.slice(-9);

  const onSuccess = (response) => response;
    const onError = (error) => {
       if ( error.request.status === 401 ) {
        logout();
       }
       
       return Promise.reject(error);
    }

    return client(options).then(onSuccess).catch(onError);
}

export default request;