import { BrowserRouter, Routes as Router, Route, Navigate, Outlet} from 'react-router-dom'
import Home from "../app/views/Home"
import Deals from '../app/views/Deals'
import SuggestDeal from '../app/views/SuggestDeal'
import App from '../app/App'
import useStore from '../store'

const PrivateRoute = ({
  children
}) => {
  const { msisdn } = useStore();

  if(msisdn === '') {
    return <Navigate to="/" replace />
  }
  return children ? children : <Outlet />;
}

const Routes = () => {
  return (
    <BrowserRouter>
      <Router>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          {/* <Route path="deals" element={
            <PrivateRoute>
              <Deals />
            </PrivateRoute>
          } /> */}
          <Route path="/deals" element={<PrivateRoute />}>
            <Route index element={<Deals />} />
            <Route path=":id" element={<Deals />} />
          </Route>
          <Route path="suggest-a-deal" element={<SuggestDeal />} />
        </Route>
        <Route path="*" element={<h1>NotFound</h1>} />
      </Router>
    </BrowserRouter>
  )
}

export default Routes