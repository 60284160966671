import React from 'react'

const Textarea = ({
  placeholder,
  textareaProps,
}) => {
  return (
    <div className="relative shadow rounded-[4px] flex flex-col bg-white">
      <textarea 
        {...textareaProps}
        className='p-4 pt-5 pl-8 peer z-20 block appearance-none bg-transparent'
        placeholder=' '
      ></textarea>
      <label 
        htmlFor={textareaProps.name}
        className='absolute text-sm text-gray-300 duration-300 transform -translate-y-4 scale-75 top-5 left-8 z-0 origin-[0] peer-focus:left-8 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4'
      >
        {placeholder}
      </label>
    </div>
  )
}

export default Textarea