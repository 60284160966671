export default function URLSearch() {
  const url = window.location.href;

  if(url.indexOf('?') === -1) {
    return null
  }

  const search = decodeURIComponent(url.split('?')[1]);

  const paramsObj = search.split("&").reduce((acc, item) => {
    const param = item.split('=');
    return {...acc, [param[0]]: param[1]}
  }, {});

  return paramsObj;
}