import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import Button from './Button';
import useStore from '../store';
import UnsubscribeButton from './UnsubscribeButton';

const subMenus = [
  ({ style, nextMenu }) => <animated.div style={{ ...style }}>
    <div className="" role="none">
      <div className='uppercase font-bold text-xl bg-primary text-white py-2 px-4'>Menu</div>
      {/* Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" */}
      <div className="divide-y divide-primary/30 px-2">
        <ul className="relative">
          <li className="py-3">
            <span
            className="text-gray-700 block px-4 text-sm" 
            role="menuitem" 
            tabIndex="-1" id="menu-item-0"
            onClick={nextMenu}
            >Account</span>
          </li>
        </ul>
        
        <div className="px-4 py-3 pb-4">
          <Link to="/suggest-a-deal">
            <Button 
              type="submit" 
              role="menuitem"
              tabIndex="-1"
              size="sm"
              wide={false}
            >
              Suggest a deal
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </animated.div>,
  ({ style, prevMenu, number }) => <animated.div style={{ ...style }}>
    <div className="" role="none">
      <div 
        className='bg-primary text-white py-2 px-4'
      >
        <div className="flex justify-between">
        <p className="uppercase font-bold text-xl">Account</p>
        <button onClick={prevMenu}>back</button>
        </div>
      </div>
      {/* Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" */}
      <div className="px-2">
        <ul className="relative">
          <li>
            <div
            className="px-4 py-3 flex justify-between" 
            role="menuitem" 
            >
              <p className="text-gray-700 text-sm">Phone number</p>
              <p className="text-primary font-medium">{number}</p>
            </div>
          </li>
        </ul>

        <div className="divide-y divide-primary/30">
        <div>
        <p className="px-4 text-primary font-medium">My subscription</p>
        <ul className='divide-y divide-primary/30'>
          <li>
            <div
            className="px-4 py-3 flex items-center justify-between" 
            role="menuitem" 
            tabIndex="-1" id="menu-item-0"
            >
              <p className="text-gray-700 text-sm">Subscription fee</p>
              <p className="font-medium text-black pr-12">0.25p</p>
            </div>
          </li>
          <li>
            <div
            className="px-4 py-3 flex items-center justify-between" 
            role="menuitem" 
            tabIndex="-1" id="menu-item-0"
            >
              <p className="text-gray-700 text-sm">Network</p>
              <p className="font-medium text-black pr-12">Telecel</p>
            </div>
          </li>
        </ul>
        </div>
        
        <UnsubscribeButton />
      </div>
      </div>
    </div>
  </animated.div>,
]

const Hamburger = ({
  list
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [index, set] = useState(0);
  const number = useStore(s=>s.msisdn);
  const transitions = useTransition(menuVisible, {
    from: { opacity: 0, transform: "translateY(-40px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-40px)" }
  })

  const subMenuTransitions = useTransition(index, {
    keys: null,
    from: { transform: 'translate3d(110%,0,0)', position: 'absolute' },
    enter: { transform: 'translate3d(0%,0,0)', position: 'relative' },
    leave: { transform: 'translate3d(-110%,0,0)', position: 'absolute'  },
  })

  return (
    <div className='relative inline-block text-left'>
      {/* Hamburger bars */}
      <div>
        <button className="flex flex-col gap-[3px]" onClick={() => setMenuVisible(true)}>
          <span className="w-[27px] h-[3px] bg-primary block"></span>
          <span className="w-[27px] h-[3px] bg-primary block"></span>
          <span className="w-[27px] h-[3px] bg-primary block"></span>
        </button>
      </div>


      {transitions(
        (style, item) =>
        item && (
          <>
          <animated.div 
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-10" 
            style={{
              opacity: style.opacity
            }} 
            onClick={() => setMenuVisible(false)}
          ></animated.div>

          <animated.div style={style} 
            className="absolute overflow-hidden left-0 z-10 -mt-4 w-72 origin-top-right rounded-b-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" 
            role="menu" 
            aria-orientation="vertical" 
            aria-labelledby="menu-button" 
            tabIndex="-1" 
          >
            {subMenuTransitions((style, i) => {
              const SubMenu = subMenus[i];
              return <SubMenu 
                style={style} 
                nextMenu={() => set(s=>(s+1) % 3)} 
                prevMenu={() => set(s=>(s-1) % 3)}
                number={number}
              />
            })}
          </animated.div>
          </>
        )
      )}
    </div>
  )
}

export default Hamburger