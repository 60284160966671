export const API_BASE_URL = process.env.NODE_ENV === 'development' 
? 'https://admin.wobotom.com' 
: 'https://admin.wobotom.com';

export const VERIFY_SUB_URL = 'https://apps.mobivs.com/TELENITY/check_sub.php';

export const TELENITY_SUB_URL = 'https://apps.mobivs.com/TELENITY/app.php';

export const TELENITY_URL = process.env.NODE_ENV === 'development' 
? 'http://telenity.wobotom.com/register' 
: 'http://telenity.wobotom.com/register';