import Select from "react-select"
import axios from 'axios'
import { useQuery } from 'react-query'
import { API_BASE_URL } from '../constants'

const Finder = ({
  onFilter
}) => {
  const {
    data
  } = useQuery(["tags"], async () => {
    const { data } = await axios.get(API_BASE_URL + '/wp-json/deals/v1/tags');
    const options = data.map((tag) => ({
      value: tag.term_id,
      label: tag.name
    }));
    return options;
  });

  return (
    <Select 
      className="finder" 
      placeholder="Filter" 
      isClearable
      options={data}
      onChange={(val) => onFilter(val)}
    />
  )
}

export default Finder