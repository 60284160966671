import React from 'react'
import IntlTelInput from 'react-intl-tel-input';
import './intl.css';

const TelInput = ({
  placeholder,
  inputProps
}) => {
  return (
    <div className="relative shadow rounded-[4px] flex flex-col bg-white">
      {/* <input type="text" id={inputProps.name} placeholder=" "
        
        {...inputProps}
      /> */}
      <IntlTelInput
        {...inputProps}
        autoHideDialCode={true}
        allowDropdown={false}
        onlyCountries={["gh"]}
        defaultCountry={"gh"}
        containerClassName="intl-tel-input h-[66px] flex justify-center"
        inputClassName='form-control z-20 block px-4 w-full text-sm text-gray-900 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer'
      />
      <label 
        htmlFor={inputProps.name}
        className='absolute text-sm text-gray-300 duration-300 transform -translate-y-4 scale-75 top-5 left-[54px] z-0 origin-[0]'
      >
        {placeholder}
      </label>
    </div>
  )
}

export default TelInput
